import { useContext } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  Icons,
} from '@cast/design-system';

import { Cluster, isAKSCluster } from 'types/cluster';

import { AgentAutoscalerSetup } from './setup-blocks/AgentAutoscalerSetup';
import { AgentSecuritySetup } from './setup-blocks/AgentSecuritySetup';
import { AssignCredentialsDialogHeader } from '../../AssignCredentialsDialogHeader';
import { AssignCredentialsDialogContext } from '../context';

type Props = {
  cluster: Cluster;
};

export const AssignCredentialsDialogSuccessSlide = ({ cluster }: Props) => {
  const { dismiss, includeSecurity, includeAutoscaler } = useContext(
    AssignCredentialsDialogContext
  );

  return (
    <Stack data-testid="success-step">
      <AssignCredentialsDialogHeader dismiss={dismiss} />
      <DialogContent>
        <Stack>
          <Typography variant="L10M" color="grey.400" mb={24}>
            Features:
          </Typography>
          <Stack direction="row" gap={16} mb={16}>
            <AgentAutoscalerSetup readonly checked={includeAutoscaler} />
            <AgentSecuritySetup readonly checked={includeSecurity} />
          </Stack>
        </Stack>
        {isAKSCluster(cluster) && includeAutoscaler && (
          <Alert
            size="small"
            sx={{ mt: 8 }}
            color="advisory"
            testId="long-aks-onboarding-alert"
            title="It might take up to 15 minutes to complete the setup."
            icon={<Icons.WarningCircle />}
          >
            In the meantime, you can continue to explore the product.
          </Alert>
        )}
      </DialogContent>
      <Divider sx={{ borderColor: 'grey.100' }} />
      <DialogActions>
        <Button onClick={dismiss} testId="done-button">
          Close
        </Button>
      </DialogActions>
    </Stack>
  );
};
