import { createContext, useContext, useEffect, useState } from 'react';

import { Stack, styled, useTheme, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import { Form, useField, useForm } from 'react-final-form';
import * as Yup from 'yup';

import {
  Step,
  Stepper,
  withProps,
  Icons,
  Button,
  DsShadow,
} from '@cast/design-system';
import { makeValidateSync } from '@cast/utils';

import { LogoFullSvg } from 'assets/svg';
import { HexIconSuccess } from 'components/hex-icons';
import { analyticsEvents } from 'core/analytics';
import { RffInput } from 'core/forms/rff';

import { LogoutButton } from './_components';
import { useContactsForm } from './_hooks';
import { contactsFormSchema } from './validation.schema';
import { ReactComponent as BrandsSvg } from '../_assets/brands.svg';
import { CountryCodeAutocomplete } from '../CountryCodeAutocomplete';
import 'yup-phone-lite';

const StepperIcon = styled(LogoFullSvg)(({ theme }) => ({
  fontSize: 16,
  width: '1em',
  height: '1em',
  color: theme.palette.grey[800],
}));

const logoSize = 80;

const validate = makeValidateSync(contactsFormSchema);

const FormContext = createContext<{
  currentStep: number;
  setCurrentStep: (step: 0 | 1) => void;
}>(undefined as never);

const ButtonBase = styled(Button)({
  height: 32,
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 600,
  borderRadius: '4px',
  paddingLeft: 12,
  paddingRight: 12,
});

const FilledButton = withProps(ButtonBase, {
  size: 'small',
});

const Step2 = () => {
  const form = useForm();
  const { setCurrentStep } = useContext(FormContext);

  useEffect(() => {
    analyticsEvents.signupFormVisited(2);
  }, []);

  return (
    <Stack>
      <Typography variant="P14R" color="grey.500" mb="24px">
        Last step! Complete your signup to immediately access free cost &
        security monitoring in CAST AI.
      </Typography>
      <RffInput
        size="medium"
        name="company"
        id="company"
        label="Company name"
        placeholder="e.g. Google"
        // disable dashlane
        inputProps={{ 'data-form-type': 'other' }}
        hintAbsolute
        sx={{ mb: '28px' }}
      />
      <Box
        display="grid"
        gridTemplateColumns="120px auto"
        gridTemplateRows="18px auto"
        columnGap="8px"
        rowGap="4px"
        mb="32px"
      >
        <Typography
          variant="P12M"
          component="p"
          color="grey.900"
          mb="4px"
          gridColumn="1 / -1"
        >
          Phone number
        </Typography>

        <CountryCodeAutocomplete name="countryCode" />

        <RffInput
          autoComplete="off"
          size="medium"
          name="phone"
          placeholder="000 000 000"
          // disable dashlane
          inputProps={{ 'data-form-type': 'other' }}
          hintAbsolute
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height={60}
        borderTop="grey.100"
        borderColor="grey.100"
      >
        <Button
          onClick={() => setCurrentStep(0)}
          startIcon={<Icons.ArrowLeft />}
          variant="ghost"
        >
          Back
        </Button>
        <FilledButton
          onClick={() => {
            form.submit();
          }}
          size="small"
        >
          Complete signup
        </FilledButton>
      </Stack>
    </Stack>
  );
};

const Step1 = () => {
  const firstName = useField('firstName');
  const lastName = useField('lastName');
  const form = useForm();
  const { setCurrentStep } = useContext(FormContext);

  useEffect(() => {
    analyticsEvents.signupFormVisited(1);
  }, []);

  return (
    <Stack>
      <Typography variant="P14R" color="grey.500" mb="24px">
        Enter your name to set up your CAST AI account.
      </Typography>
      <RffInput
        size="medium"
        name="firstName"
        id="firstName"
        label="First name"
        placeholder="e.g. John"
        // disable dashlane
        inputProps={{ 'data-form-type': 'other' }}
        hintAbsolute
        sx={{ mb: '20px' }}
      />
      <RffInput
        size="medium"
        name="lastName"
        id="lastName"
        label="Last name"
        placeholder="e.g. Smith"
        // disable dashlane
        inputProps={{ 'data-form-type': 'other' }}
        hintAbsolute
        sx={{ mb: '20px' }}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        height={60}
        mx="-32px"
        pr="32px"
        borderTop="grey.100"
        borderColor="grey.100"
      >
        <Button
          variant="ghost"
          onClick={() => {
            if (firstName.meta.valid && lastName.meta.valid) {
              setCurrentStep(1);
            } else {
              form.submit();
            }
          }}
          endIcon={<Icons.ArrowRight />}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export const MultiStepContactsForm = () => {
  const { showForm, submitFormData, closeContactsForm, isSubmitted } =
    useContactsForm();
  const [activeStep, setActiveStep] = useState<0 | 1>(0);
  const { palette, shadows } = useTheme();

  if (!showForm) {
    return null;
  }

  return (
    <FormContext.Provider
      value={{ currentStep: activeStep, setCurrentStep: setActiveStep }}
    >
      <Box
        className={clsx(isSubmitted && 'animating')}
        sx={{
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          inset: 0,
          zIndex: 'modal',
          backdropFilter: 'blur(2.5px)',
          background: 'rgb(0 0 0 / 80%)',
          flexDirection: 'column',
          margin: 'auto',
          // Animation
          transition: '.5s opacity linear .75s',
          opacity: 1,
          '& .form-wrapper': {
            transition: '.25s max-height linear .25s, .25s width linear .5s',
            maxHeight: 600,
          },
          '& .cast-logo': {
            opacity: 0,
            transition: '.5s opacity, .5s font-size .75s',
          },
          '& .leading-companies, & .DS-Stepper-root': {
            opacity: 1,
            transition: '.5s opacity',
          },
          '&.animating': {
            opacity: 0,
            '& .cast-logo': {
              opacity: 1,
              fontSize: '200px',
            },
            '& .leading-companies, & .DS-Stepper-root': {
              opacity: 0,
            },
            '& .form-wrapper': {
              maxHeight: '3px',
              width: 0,
            },
          },
        }}
        onTransitionEnd={(event) => {
          if (
            event.currentTarget === event.target &&
            event.propertyName === 'opacity'
          ) {
            closeContactsForm();
          }
        }}
        data-testid="contacts-form"
      >
        <LogoutButton />
        <Stepper
          activeStep={activeStep}
          sx={{ width: 427, mb: '20px', px: '12px' }}
          size="small"
        >
          <Step completed={activeStep > 0} />
          <Step completed={isSubmitted} />
          <Step
            sx={{ '& .DS-Step-content': { display: 'flex' } }}
            content={<StepperIcon />}
          />
        </Stepper>
        <Stack position="relative">
          <Stack
            direction="row"
            justifyContent="center"
            top={-(logoSize / 2)}
            left={0}
            right={0}
            zIndex={1}
            position="absolute"
            sx={{
              opacity: isSubmitted ? 1 : 0,
              transition: '.5s opacity linear',
            }}
          >
            <HexIconSuccess
              size={logoSize}
              icon={
                <Icons.CheckCircle weight="fill" color={palette.green[500]} />
              }
            />
          </Stack>
          <Box className="form-wrapper" width={427} overflow="hidden">
            <Box
              px="32px"
              bgcolor="common.white"
              borderRadius="4px"
              position="relative"
              boxShadow={shadows[DsShadow.SHADOW]}
            >
              <Typography
                variant="h5"
                height={72}
                display="flex"
                alignItems="center"
              >
                Set up your account
              </Typography>
              <Box
                sx={{
                  height: '16px',
                  mx: '-32px',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
              />
              <Form
                onSubmit={({
                  firstName,
                  lastName,
                  company,
                  countryCode,
                  phone,
                }: Yup.InferType<typeof contactsFormSchema>) => {
                  submitFormData({
                    firstName,
                    lastName,
                    company,
                    phone: `${countryCode?.phone ?? ''}${phone.replaceAll(
                      ' ',
                      ''
                    )}`,
                  });
                }}
                validate={validate}
                render={() => {
                  switch (activeStep) {
                    case 0:
                      return <Step1 />;
                    case 1:
                      return <Step2 />;
                    default:
                      return null;
                  }
                }}
              />
            </Box>
          </Box>
        </Stack>
        <Stack
          className="leading-companies"
          mt="32px"
          gap="16px"
          alignItems="center"
        >
          <Typography variant="h6" color="white">
            Leading companies automate their Kubernetes with CAST AI
          </Typography>
          <BrandsSvg />
        </Stack>
      </Box>
    </FormContext.Provider>
  );
};
