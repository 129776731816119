import { SyntheticEvent, useCallback, useContext } from 'react';

import { Stack, StackProps, styled } from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import { bindToggle } from 'material-ui-popup-state/core';

import { Button } from '../../../buttons';
import { DropdownContext } from '../DropdownContext';

const StyledControls = styled(Stack, {
  name: 'DsAutocomplete',
  slot: 'Controls',
  target: 'DsAutocomplete-Controls',
  overridesResolver: (_, styles) => [styles.controls],
})<StackProps>({});

export const DropdownControls = () => {
  const {
    dropdownProps: { showClearAll, onChange, size, options, ...dropdownProps },
    popupState,
    autocomplete: {
      states: { value },
      getters,
      actions,
    },
  } = useContext(DropdownContext);

  const hasSelectedValues = Array.isArray(value) ? value.length : false;
  const allSelected = Array.isArray(value) && value.length === options?.length;

  const showSelectAll =
    'showSelectAll' in dropdownProps && dropdownProps.showSelectAll;

  const handleOnApply = useCallback(
    (e: SyntheticEvent) => {
      actions.saveValue();
      const _value = cloneDeep(value);
      const _options = (options ?? []).filter((item) => {
        return _value.some((v: any) => getters.isOptionEqualToValue?.(item, v));
      });
      onChange?.(_options, _value);
      bindToggle(popupState).onClick(e);
    },
    [actions, value, options, onChange, popupState, getters]
  );

  return (
    <StyledControls
      direction="row"
      justifyContent="flex-end"
      py={size === 'large' ? '6px' : '4px'}
      px={size === 'large' ? '12px' : '10px'}
      gap={size === 'large' ? '12px' : '8px'}
      borderTop="1px solid"
      borderColor="grey.100"
    >
      {showSelectAll && (
        <Button
          size={size === 'large' ? 'large' : 'medium'}
          variant="text"
          disabled={allSelected}
          onClick={() => actions.selectAll()}
          data-testid="dropdown-select-all"
          sx={{ marginRight: 'auto' }}
        >
          Select all
        </Button>
      )}
      {showClearAll && (
        <Button
          size={size === 'large' ? 'large' : 'medium'}
          variant="text"
          disabled={!hasSelectedValues}
          onClick={() => actions.clearValue()}
          data-testid="dropdown-clear-selection"
        >
          Clear all
        </Button>
      )}
      <Button
        size={size === 'large' ? 'medium' : 'small'}
        variant="primary"
        onClick={handleOnApply}
        data-testid="dropdown-apply-selection"
      >
        Apply
      </Button>
    </StyledControls>
  );
};
