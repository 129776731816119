import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { RequiredProps, UsersOrganization } from '@cast/types';

import { apiClient, QueryKeys } from 'core/react-query';
import { notify } from 'core/snackbars';
import { parseApiError } from 'utils/api';

type UseUpdateOrganizationMutation = UseMutationResult<
  unknown,
  unknown,
  RequiredProps<Partial<UsersOrganization>, 'id' | 'name'>
>;

export const useUpdateOrganizationMutation = (
  onSuccess?: () => void
): UseUpdateOrganizationMutation => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) =>
      apiClient.user.updateOrganization({
        id: payload.id,
        organization: payload,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.ORGANIZATIONS],
      });
      onSuccess?.();
    },
    onError: (error: unknown) => {
      notify.error(parseApiError(error));
    },
  });
};
