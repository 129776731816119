import { forwardRef } from 'react';

import {
  Box,
  BoxProps,
  Divider,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useMatch } from 'react-router-dom';

import { ChipProps, Icons, mergeSx, Scroller } from '@cast/design-system';
import { K8sProvider } from '@cast/types';
import { isDemoMode, usePath } from '@cast/utils';

import {
  CostMonitoringVisualSvg,
  LogoFullSvg,
  LogoSmallSvg,
  OrgOptimizationSvg,
  SecurityVisualSvg,
} from 'assets/svg';
import { IS_PROD_EU, SUPPORTS_NODE_CONFIGURATION } from 'common/constants';
import { BetaLabelChip, LabelChip } from 'components/chips';
import { Link } from 'components/router';
import { computedFlags, flags } from 'core/flags';
import {
  makeAllocationGroupsCostMonitoringLink,
  makeClusterCostMonitoringLink,
  makeCostComparisonLink,
  makeNamespacesCostMonitoringLink,
  makeWorkloadsCostMonitoringLink,
} from 'core/navigation';
import { isDisconnectedForMonth } from 'features/cluster';
import { useIsPhase1WoopEnabled } from 'features/cluster/workload-optimization/hooks/useIsPhase1WoopEnabled';
import {
  getOrgSecurityRoutePath,
  makeOrgCompliancePath,
  makeOrgVulnerabilityManagementPath,
} from 'features/organization/security-insights';
import { useClusterQuery } from 'hooks/queries/cluster';
import { useClusters } from 'hooks/useClusters';
import { useOrgHasClustersWithProvider } from 'hooks/useOrgHasClustersWithProvider';

import { DividerWithText, SidebarSizeSwitcher } from './_components';
import { useAutoscalerSidebarItemProps } from './_hooks/useAutoscalerSidebarItemProps';
import { useRebalancingPlanSidebarItemProps } from './_hooks/useRabalancingPlanSidebarItemProps';
import { ClusterSidebarItem } from './ClusterSidebarItem';
import { SidebarItem } from './SidebarItem';
import { useMainLayout } from '../hooks';

export const minimizedWidth = '64px';

export const maximizedWidth = '232px';

const COMMITMENTS_PROVIDERS: Array<`${K8sProvider}`> = ['aks', 'gke'];

const Container = styled(Stack)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  alignSelf: 'stretch',
  background: theme.palette.grey[100],

  '&:before': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '100%',
    left: '-40px',
    top: '0px',
    background:
      'linear-gradient(270deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 72.92%)',
  },

  '&:after': {
    content: '""',
    position: 'absolute',
    width: '40px',
    height: '100%',
    right: '-40px',
    top: '0px',
    background:
      'linear-gradient(90deg, rgba(8, 41, 57, 0.05) 0%, rgba(8, 41, 57, 0) 72.92%)',
  },
  '& .sidebar-size-switcher': {
    opacity: 0,
  },
  '&:hover .sidebar-size-switcher': {
    opacity: 1,
  },
  '&:has(:focus-visible) .sidebar-size-switcher': {
    opacity: 1,
  },
}));

type BetaLabelProps = ChipProps;
const BetaLabel = ({ sx }: BetaLabelProps) => (
  <BetaLabelChip
    size="micro"
    sx={mergeSx(
      {
        mr: 'auto',
        alignSelf: 'flex-start',
        transform: 'translateX(-8px)',
      },
      sx
    )}
  />
);

const useRouteMatch = () => useMatch('/external-clusters/:clusterId/*');

type WorkloadOptimizationItemProps = {
  clusterId?: string;
  isManaged: boolean;
};

const WorkloadOptimizationItem = ({
  isManaged,
  clusterId,
}: WorkloadOptimizationItemProps) => {
  const match = useRouteMatch();
  const isDemo = isDemoMode();
  const { isPhase1WoopEnabled } = useIsPhase1WoopEnabled(clusterId, !isManaged);

  if (
    !flags['page-modules'].cluster['workload-optimization'].value &&
    !isDemo
  ) {
    return null;
  }

  return (
    <SidebarItem
      title="Workload autoscaler"
      icon={<Icons.SelectionAll />}
      url={`${match?.pathnameBase}/workload-optimization`}
      disabled={!isManaged && !isPhase1WoopEnabled && !isDemo}
    >
      <SidebarItem
        title="Optimization"
        url={
          flags['page-modules'].cluster['workload-optimization'].hpa.value
            ? `${match?.pathnameBase}/workload-optimization/workloads`
            : `${match?.pathnameBase}/workload-optimization/optimization`
        }
      />
      <SidebarItem
        title="Event log"
        url={`${match?.pathnameBase}/workload-optimization/events`}
      />
    </SidebarItem>
  );
};

const AutoscalerItem = ({
  nodeTemplatesDisabled,
  nodeConfigurationDisabled,
}: {
  nodeTemplatesDisabled: boolean | undefined;
  nodeConfigurationDisabled: boolean | undefined;
}) => {
  const match = useRouteMatch();
  const autoscalerSidebarItemProps = useAutoscalerSidebarItemProps();

  return (
    <SidebarItem
      title="Autoscaler"
      icon={<Icons.ToggleLeft />}
      url={`${match?.pathnameBase}/autoscaler`}
      flag={computedFlags(flags['page-modules'].cluster.autoscaler)}
      {...autoscalerSidebarItemProps}
    >
      <SidebarItem
        title="Settings"
        url={`${match?.pathnameBase}/autoscaler/settings`}
        flag={flags['page-modules'].cluster.autoscaler.settings.value}
      />

      <SidebarItem
        title="Node templates"
        url={`${match?.pathnameBase}/autoscaler/node-selection-templates`}
        disabled={nodeTemplatesDisabled}
        tooltip={
          nodeTemplatesDisabled
            ? 'Feature is not supported for this provider'
            : undefined
        }
        flag={flags['page-modules'].cluster.autoscaler['node-templates'].value}
      />

      <SidebarItem
        title="Node configuration"
        url={`${match?.pathnameBase}/autoscaler/node-configurations`}
        disabled={nodeConfigurationDisabled}
        tooltip={
          nodeConfigurationDisabled
            ? 'Feature is not supported for this provider'
            : undefined
        }
        flag={
          flags['page-modules'].cluster.autoscaler['node-configurations'].value
        }
      />
    </SidebarItem>
  );
};

const RebalancerItem = () => {
  const rebalancingPlanSidebarItemProps = useRebalancingPlanSidebarItemProps();
  return (
    <SidebarItem
      title="Rebalancer"
      icon={<Icons.Rebalancer />}
      flag={flags['page-modules'].cluster.rebalancer.value}
      {...rebalancingPlanSidebarItemProps}
    />
  );
};

type SidebarProps = BoxProps;

export const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
  ({ ...boxProps }: SidebarProps, ref) => {
    const match = useRouteMatch();

    const { sidebarState } = useMainLayout();
    const theme = useTheme();

    const clusterId = match?.params?.clusterId;
    const { clusters } = useClusters({
      polling: false,
      enabled: Boolean(clusterId),
    });
    const { cluster } = useClusterQuery(clusterId);

    const isManaged = Boolean(cluster?.credentialsId);
    const isDisconnected = cluster && isDisconnectedForMonth(cluster);

    const _clusterOptions = clusters.map((cluster) => {
      return {
        cluster,
        selected: cluster.id === clusterId,
      };
    });

    const hasAksOrGkeClusters = useOrgHasClustersWithProvider(
      COMMITMENTS_PROVIDERS
    );

    const orgComplianceLink = usePath(makeOrgCompliancePath);
    const orgVulnerabilityManagementLink = usePath(
      makeOrgVulnerabilityManagementPath
    );

    const clusterCostMonitoringLink = usePath(
      () =>
        makeClusterCostMonitoringLink({
          clusterId: cluster?.id,
        }),
      { enabled: !!cluster }
    );
    const workloadsCostMonitoringLink = usePath(
      () =>
        makeWorkloadsCostMonitoringLink({
          clusterId: cluster?.id,
        }),
      { enabled: !!cluster }
    );
    const namespacesConstMonitoringLink = usePath(
      () =>
        makeNamespacesCostMonitoringLink({
          clusterId: cluster?.id,
        }),
      { enabled: !!cluster }
    );
    const allocationGroupsCostMonitoringLink = usePath(
      () =>
        makeAllocationGroupsCostMonitoringLink({
          clusterId: cluster?.id,
        }),
      { enabled: !!cluster }
    );

    const costComparisonLink = usePath(
      () => makeCostComparisonLink({ clusterId: cluster?.id }),
      { enabled: !!cluster }
    );

    if (!sidebarState) {
      return null;
    }

    const isMinimized = sidebarState === 'minimized';
    const isDemo = isDemoMode();

    const nodeTemplatesDisabled =
      cluster &&
      !isDemo &&
      !SUPPORTS_NODE_CONFIGURATION.includes(cluster.providerType);
    const nodeConfigurationDisabled =
      cluster &&
      !isDemo &&
      (!isManaged ||
        isDisconnected ||
        !SUPPORTS_NODE_CONFIGURATION.includes(cluster.providerType));
    const auditLogDisabled = !isDemo && (!isManaged || isDisconnected);

    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        position="sticky"
        top={0}
        height="100vh"
        zIndex={1}
        sx={{ backgroundColor: 'grey.100' }}
        data-testid="sidebar"
        {...boxProps}
        ref={ref}
      >
        <Container width={isMinimized ? minimizedWidth : maximizedWidth}>
          <Box
            display="grid"
            gridTemplateRows="max-content max-content auto"
            overflow={cluster ? 'unset' : 'hidden'}
          >
            <Box position="absolute" top="38px" right="-10px" zIndex="1">
              <SidebarSizeSwitcher
                className="sidebar-size-switcher"
                sx={{
                  '&:hover svg': {
                    color: 'grey.900',
                  },
                }}
              />
            </Box>

            <Stack
              px={isMinimized ? '0px' : '8px'}
              ml={isMinimized ? '0px' : '13px'}
              height="60px"
              py="14px"
              alignItems={isMinimized ? 'center' : 'flex-start'}
              justifyContent="center"
            >
              <Link
                to="/dashboard"
                style={{ textDecoration: 'none' }}
                data-testid="app-logo"
              >
                {isMinimized ? (
                  <LogoSmallSvg
                    aria-label="logo"
                    color={theme.palette.grey[800]}
                  />
                ) : (
                  <Stack direction="row" gap={4}>
                    <LogoFullSvg
                      aria-label="logo"
                      color={theme.palette.grey[800]}
                    />
                    {IS_PROD_EU && (
                      <LabelChip
                        size="micro"
                        sx={{
                          backgroundColor: 'grey.800',
                          color: 'blue.50',
                          mt: 11,
                        }}
                      >
                        EU
                      </LabelChip>
                    )}
                  </Stack>
                )}
              </Link>
            </Stack>

            <Typography
              variant="L10M"
              color="textSecondary"
              pl={isMinimized ? 0 : 20}
              pr={isMinimized ? 0 : 8}
              mb={10}
              textAlign={isMinimized ? 'center' : 'left'}
            >
              {isMinimized ? 'org.' : 'organization'}
            </Typography>

            <Scroller
              sx={{
                px: isMinimized ? '0px' : '8px',
                '& .os-scrollbar-vertical .os-scrollbar-track': {
                  pl: '2px !important',
                },
              }}
            >
              <Stack gap={8}>
                <SidebarItem
                  title="Cluster list"
                  icon={<Icons.Hexagon height={18} weight="fill" />}
                  url="/dashboard"
                />

                <SidebarItem
                  title="Optimization"
                  icon={<OrgOptimizationSvg />}
                  url="/settings/scheduled-rebalancing"
                  disabled={isDisconnected}
                  flag={computedFlags(flags['page-modules'].organization)}
                >
                  <SidebarItem
                    title="Scheduled rebalancing"
                    url="/settings/scheduled-rebalancing"
                    flag={
                      flags['page-modules'].organization[
                        'scheduled-rebalancing'
                      ].value
                    }
                  />

                  <SidebarItem
                    isSubItem
                    title="Commitments"
                    url="/commitments"
                    flag={
                      hasAksOrGkeClusters &&
                      flags['page-modules'].organization['commitments'].value
                    }
                  />

                  <SidebarItem
                    title="Billing report"
                    url="/organization/billing-report#sidebar"
                  />
                </SidebarItem>

                <SidebarItem
                  title="Cost monitoring"
                  icon={<CostMonitoringVisualSvg />}
                  url="/organization/cost-report"
                >
                  <SidebarItem
                    isSubItem
                    title="Clusters"
                    url="/organization/cost-report/clusters"
                  />
                  <SidebarItem
                    isSubItem
                    title="Allocation groups"
                    url="/organization/cost-report/allocation-groups"
                    endAdornment={<BetaLabel />}
                  />
                </SidebarItem>

                <SidebarItem
                  title="Security"
                  icon={<SecurityVisualSvg width={16} height={16} />}
                  url={
                    flags['page-modules']['organization-security']['dashboard']
                      .value
                      ? '/organization/security/dashboard'
                      : '/organization/security/compliance'
                  }
                  flag={computedFlags(
                    flags['page-modules']['organization-security']
                  )}
                  disabled={isDisconnected}
                >
                  <SidebarItem
                    title="Dashboard"
                    url="/organization/security/dashboard"
                    flag={
                      flags['page-modules']['organization-security'][
                        'dashboard'
                      ].value
                    }
                  />
                  <SidebarItem
                    title="Compliance"
                    url={orgComplianceLink}
                    flag={
                      flags['page-modules']['organization-security'][
                        'best-practices'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={orgVulnerabilityManagementLink}
                    title="Vulnerabilities"
                    flag={
                      flags['page-modules']['organization-security'][
                        'image-security'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={getOrgSecurityRoutePath('attack-path')}
                    title="Attack paths"
                    flag={
                      flags['page-modules']['organization-security'][
                        'attack-path'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={getOrgSecurityRoutePath('runtime')}
                    title="Runtime"
                    flag={
                      flags['page-modules']['organization-security'][
                        'anomalies'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={getOrgSecurityRoutePath('workloads')}
                    title="Workloads"
                    flag={
                      flags['page-modules']['organization-security'][
                        'workloads'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={getOrgSecurityRoutePath('node-os-updates')}
                    title="Node OS updates"
                    flag={
                      flags['page-modules']['organization-security'][
                        'node-os-updates'
                      ].value
                    }
                  />
                  <SidebarItem
                    url={getOrgSecurityRoutePath('settings')}
                    title="Settings"
                    flag={
                      flags['page-modules']['organization-security'].settings
                        .value
                    }
                  />
                </SidebarItem>
              </Stack>
            </Scroller>
          </Box>

          {!cluster &&
            flags['page-modules']['organization-llm']['cost-report'].value && (
              <Stack>
                <Divider
                  color={theme.palette.grey[50]}
                  sx={{
                    margin: '28px 0px 24px',
                  }}
                />

                <Box
                  sx={{
                    px: isMinimized ? '0px' : '8px',
                  }}
                >
                  <Typography
                    variant="L10M"
                    color="textSecondary"
                    pl={isMinimized ? 0 : 12}
                    mb={10}
                    textAlign={isMinimized ? 'center' : 'left'}
                  >
                    {isMinimized ? 'ext.' : 'extra features'}
                  </Typography>

                  <Stack gap={8}>
                    <SidebarItem
                      title="AI Optimizer"
                      icon={<Icons.Brain height={18} weight="fill" />}
                      url="/llm"
                    >
                      <SidebarItem title="Cost Report" url="/llm/report" />
                      <SidebarItem
                        title="Providers"
                        url="/llm/providers"
                        flag={
                          flags['page-modules']['organization-llm'].providers
                            .value
                        }
                      />
                      <SidebarItem
                        title="Playground"
                        url="/llm/playground"
                        flag={
                          flags['page-modules']['organization-llm'].playground
                            .value
                        }
                      />
                    </SidebarItem>
                  </Stack>
                </Box>
              </Stack>
            )}

          {cluster && (
            <Stack
              display="grid"
              gridTemplateRows="max-content max-content auto"
              overflow="hidden"
            >
              <Divider
                color={theme.palette.grey[50]}
                sx={{
                  margin: '8px 0px 10px',
                }}
              />

              <ClusterSidebarItem data={_clusterOptions} />

              <Scroller
                sx={{
                  px: isMinimized ? '0px' : '8px',
                  '& .os-scrollbar-vertical .os-scrollbar-track': {
                    pl: '2px !important',
                  },
                }}
              >
                <Stack gap="8px" height="100%">
                  <SidebarItem
                    title="Dashboard"
                    icon={<Icons.Gauge />}
                    url={`${match?.pathnameBase}/dashboard`}
                  />

                  <SidebarItem
                    title="Cost monitoring"
                    icon={<Icons.ChartBar />}
                    url={`${match?.pathnameBase}/cost-report`}
                    flag={computedFlags(
                      flags['page-modules'].cluster['cost-report']
                    )}
                    disabled={isDisconnected}
                  >
                    <SidebarItem
                      title="Cluster"
                      url={clusterCostMonitoringLink}
                      flag={computedFlags(
                        flags['page-modules'].cluster['cost-report'].cluster
                      )}
                    />

                    <SidebarItem
                      title="Workloads"
                      url={workloadsCostMonitoringLink}
                      flag={computedFlags(
                        flags['page-modules'].cluster['cost-report'].workloads
                      )}
                    />

                    <SidebarItem
                      title="Namespaces"
                      url={namespacesConstMonitoringLink}
                      flag={computedFlags(
                        flags['page-modules'].cluster['cost-report'].namespaces
                      )}
                    />

                    <SidebarItem
                      title="Allocation groups"
                      url={allocationGroupsCostMonitoringLink}
                      flag={computedFlags(
                        flags['page-modules'].cluster['cost-report'][
                          'allocation-groups'
                        ]
                      )}
                    />

                    <SidebarItem
                      title="Cost comparison"
                      url={costComparisonLink}
                      flag={
                        flags['page-modules'].cluster['cost-report'].comparison
                          .value
                      }
                      disabled={!isManaged}
                      tooltip={
                        !isManaged ? 'Enable cost optimization' : undefined
                      }
                    />
                  </SidebarItem>

                  {flags['page-modules'].cluster['savings-progress'].value ? (
                    <SidebarItem
                      title="Savings"
                      icon={<Icons.CurrencyDollarSimple />}
                      url={`${match?.pathnameBase}/available-savings`}
                      flag={
                        flags['page-modules'].cluster['available-savings']
                          .value &&
                        flags['page-modules'].cluster['savings-progress'].value
                      }
                    >
                      <SidebarItem
                        title="Available savings"
                        url={`${match?.pathnameBase}/available-savings`}
                        disabled={isDisconnected}
                        flag={
                          flags['page-modules'].cluster['available-savings']
                            .value
                        }
                      />
                      <SidebarItem
                        title="Savings progress"
                        url={`${match?.pathnameBase}/savings/savings-progress`}
                        disabled={isDisconnected || !isManaged}
                        flag={
                          flags['page-modules'].cluster['savings-progress']
                            .value
                        }
                      />
                    </SidebarItem>
                  ) : (
                    <SidebarItem
                      title="Available savings"
                      icon={<Icons.CurrencyDollarSimple />}
                      url={`${match?.pathnameBase}/available-savings`}
                      disabled={isDisconnected}
                      flag={
                        flags['page-modules'].cluster['available-savings'].value
                      }
                    />
                  )}

                  {!isDemo && (
                    <WorkloadOptimizationItem
                      isManaged={isManaged}
                      clusterId={cluster.id}
                    />
                  )}

                  <SidebarItem
                    title="Node list"
                    icon={
                      <Icons.NodeIcon size={16} borderColor="currentColor" />
                    }
                    url={`${match?.pathnameBase}/nodes`}
                    flag={flags['page-modules'].cluster.node.value}
                    disabled={isDisconnected}
                  />

                  {!isDemo && (
                    <>
                      <AutoscalerItem
                        nodeConfigurationDisabled={nodeConfigurationDisabled}
                        nodeTemplatesDisabled={nodeTemplatesDisabled}
                      />
                      <RebalancerItem />
                    </>
                  )}

                  {isDemo && (
                    <>
                      <DividerWithText
                        text={!isMinimized ? 'Premium features' : undefined}
                      />
                      <AutoscalerItem
                        nodeConfigurationDisabled={nodeConfigurationDisabled}
                        nodeTemplatesDisabled={nodeTemplatesDisabled}
                      />

                      <WorkloadOptimizationItem isManaged />
                      <RebalancerItem />
                    </>
                  )}

                  <SidebarItem
                    title="Audit log"
                    icon={<Icons.SquareHalf />}
                    url={`${match?.pathnameBase}/audit`}
                    flag={flags['page-modules'].cluster['audit-log'].value}
                    disabled={auditLogDisabled}
                    tooltip={
                      auditLogDisabled ? 'Enable cost optimization' : undefined
                    }
                  />
                </Stack>
              </Scroller>
            </Stack>
          )}
        </Container>
      </Box>
    );
  }
);

Sidebar.displayName = 'Sidebar';
