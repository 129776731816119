/* tslint:disable */
/* eslint-disable */
/**
 * CAST.AI API documentation
 * ### Feature maturity indicators (may appear on resources, operations and request/response fields)  - **(BETA)** - new or experimental features, subject to change  - **(Deprecated)** - outdated or replaced features (a reason and removal date will be provided in the description) 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest } from '../models';
// @ts-ignore
import { AioptimizerV1CreatePlaygroundChatCompletionsRequest } from '../models';
// @ts-ignore
import { AioptimizerV1CreatePlaygroundChatCompletionsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1ListPlaygroundRateLimitsResponse } from '../models';
// @ts-ignore
import { AioptimizerV1PlaygroundRateLimit } from '../models';
/**
 * AIOptimizerPlaygroundAPIApi - axios parameter creator
 * @export
 */
export const AIOptimizerPlaygroundAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create chat completions using the selected playground providers and registered customer\'s providers.
         * @param {AioptimizerV1CreatePlaygroundChatCompletionsRequest} body Defines the request for creating chat completions in playground.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions: async (body: AioptimizerV1CreatePlaygroundChatCompletionsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions', 'body', body)
            const localVarPath = `/v1/llm/playground-chat-completions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List rate limits in AI Optimizer playground for the given organization.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPIListPlaygroundRateLimits: async (organizationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('aIOptimizerPlaygroundAPIListPlaygroundRateLimits', 'organizationId', organizationId)
            const localVarPath = `/v1/llm/organizations/{organizationId}/playground-rate-limits`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update organization\'s playground rate limit for specified period.
         * @param {string} organizationId 
         * @param {number} rateLimitPeriodSeconds Period in seconds for which the rate limit is defined.
         * @param {AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest} rateLimit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit: async (organizationId: string, rateLimitPeriodSeconds: number, rateLimit: AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit', 'organizationId', organizationId)
            // verify required parameter 'rateLimitPeriodSeconds' is not null or undefined
            assertParamExists('aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit', 'rateLimitPeriodSeconds', rateLimitPeriodSeconds)
            // verify required parameter 'rateLimit' is not null or undefined
            assertParamExists('aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit', 'rateLimit', rateLimit)
            const localVarPath = `/v1/llm/organizations/{organizationId}/playground-rate-limits/{rateLimit.periodSeconds}`
                .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
                .replace(`{${"rateLimit.periodSeconds"}}`, encodeURIComponent(String(rateLimitPeriodSeconds)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "X-API-Key", configuration)

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rateLimit, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AIOptimizerPlaygroundAPIApi - functional programming interface
 * @export
 */
export const AIOptimizerPlaygroundAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AIOptimizerPlaygroundAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create chat completions using the selected playground providers and registered customer\'s providers.
         * @param {AioptimizerV1CreatePlaygroundChatCompletionsRequest} body Defines the request for creating chat completions in playground.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(body: AioptimizerV1CreatePlaygroundChatCompletionsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1CreatePlaygroundChatCompletionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(body, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerPlaygroundAPIApi.aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List rate limits in AI Optimizer playground for the given organization.
         * @param {string} organizationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerPlaygroundAPIListPlaygroundRateLimits(organizationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1ListPlaygroundRateLimitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerPlaygroundAPIListPlaygroundRateLimits(organizationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerPlaygroundAPIApi.aIOptimizerPlaygroundAPIListPlaygroundRateLimits']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update organization\'s playground rate limit for specified period.
         * @param {string} organizationId 
         * @param {number} rateLimitPeriodSeconds Period in seconds for which the rate limit is defined.
         * @param {AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest} rateLimit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(organizationId: string, rateLimitPeriodSeconds: number, rateLimit: AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AioptimizerV1PlaygroundRateLimit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(organizationId, rateLimitPeriodSeconds, rateLimit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AIOptimizerPlaygroundAPIApi.aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AIOptimizerPlaygroundAPIApi - factory interface
 * @export
 */
export const AIOptimizerPlaygroundAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AIOptimizerPlaygroundAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create chat completions using the selected playground providers and registered customer\'s providers.
         * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1CreatePlaygroundChatCompletionsResponse> {
            return localVarFp.aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List rate limits in AI Optimizer playground for the given organization.
         * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPIListPlaygroundRateLimits(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1ListPlaygroundRateLimitsResponse> {
            return localVarFp.aIOptimizerPlaygroundAPIListPlaygroundRateLimits(requestParameters.organizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update organization\'s playground rate limit for specified period.
         * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest, options?: RawAxiosRequestConfig): AxiosPromise<AioptimizerV1PlaygroundRateLimit> {
            return localVarFp.aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(requestParameters.organizationId, requestParameters.rateLimitPeriodSeconds, requestParameters.rateLimit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions operation in AIOptimizerPlaygroundAPIApi.
 * @export
 * @interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest
 */
export interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest {
    /**
     * Defines the request for creating chat completions in playground.
     * @type {AioptimizerV1CreatePlaygroundChatCompletionsRequest}
     * @memberof AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletions
     */
    readonly body: AioptimizerV1CreatePlaygroundChatCompletionsRequest
}

/**
 * Request parameters for aIOptimizerPlaygroundAPIListPlaygroundRateLimits operation in AIOptimizerPlaygroundAPIApi.
 * @export
 * @interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest
 */
export interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimits
     */
    readonly organizationId: string
}

/**
 * Request parameters for aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit operation in AIOptimizerPlaygroundAPIApi.
 * @export
 * @interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest
 */
export interface AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest {
    /**
     * 
     * @type {string}
     * @memberof AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit
     */
    readonly organizationId: string

    /**
     * Period in seconds for which the rate limit is defined.
     * @type {number}
     * @memberof AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit
     */
    readonly rateLimitPeriodSeconds: number

    /**
     * 
     * @type {AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest}
     * @memberof AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit
     */
    readonly rateLimit: AIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest
}

/**
 * AIOptimizerPlaygroundAPIApi - object-oriented interface
 * @export
 * @class AIOptimizerPlaygroundAPIApi
 * @extends {BaseAPI}
 */
export class AIOptimizerPlaygroundAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create chat completions using the selected playground providers and registered customer\'s providers.
     * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerPlaygroundAPIApi
     */
    public aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPICreatePlaygroundChatCompletionsRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerPlaygroundAPIApiFp(this.configuration).aIOptimizerPlaygroundAPICreatePlaygroundChatCompletions(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List rate limits in AI Optimizer playground for the given organization.
     * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerPlaygroundAPIApi
     */
    public aIOptimizerPlaygroundAPIListPlaygroundRateLimits(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIListPlaygroundRateLimitsRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerPlaygroundAPIApiFp(this.configuration).aIOptimizerPlaygroundAPIListPlaygroundRateLimits(requestParameters.organizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update organization\'s playground rate limit for specified period.
     * @param {AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AIOptimizerPlaygroundAPIApi
     */
    public aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(requestParameters: AIOptimizerPlaygroundAPIApiAIOptimizerPlaygroundAPIUpdatePlaygroundRateLimitRequest, options?: RawAxiosRequestConfig) {
        return AIOptimizerPlaygroundAPIApiFp(this.configuration).aIOptimizerPlaygroundAPIUpdatePlaygroundRateLimit(requestParameters.organizationId, requestParameters.rateLimitPeriodSeconds, requestParameters.rateLimit, options).then((request) => request(this.axios, this.basePath));
    }
}

