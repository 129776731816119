import { useEffect } from 'react';

import {
  Stack,
  styled,
  useTheme,
  Box,
  Collapse,
  Typography,
} from '@mui/material';
import { Form } from 'react-final-form';
import * as Yup from 'yup';

import { Button, Icons, DsShadow } from '@cast/design-system';
import { removeHiddenChars, makeValidateSync } from '@cast/utils';

import { LogoSmallSvg } from 'assets/svg';
import { HexIconSuccess } from 'components/hex-icons';
import { analyticsEvents } from 'core/analytics';
import { RffInput } from 'core/forms/rff';

import { LogoutButton } from './_components';
import { useContactsForm } from './_hooks';
import { contactsFormSchema } from './validation.schema';
import { ReactComponent as BrandsSvg } from '../_assets/brands.svg';
import { CountryCodeAutocomplete } from '../CountryCodeAutocomplete';
import 'yup-phone-lite';

const logoSize = 80;

const StepperIcon = styled(LogoSmallSvg)(({ theme }) => ({
  color: theme.palette.green[500],
}));

const validate = makeValidateSync(contactsFormSchema);

export const ContactsFormDialog = () => {
  const { showForm, submitFormData, closeContactsForm, isSubmitted } =
    useContactsForm();
  const { palette, shadows } = useTheme();

  useEffect(() => {
    if (showForm) {
      analyticsEvents.signupFormVisited();
    }
  }, [showForm]);

  if (!showForm) {
    return null;
  }

  return (
    <Box
      sx={{
        transition: '1s opacity',
        transitionDelay: '2000ms',
        opacity: isSubmitted ? 0 : 1,
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        inset: 0,
        zIndex: 'modal',
        backdropFilter: 'blur(2.5px)',
        background: 'rgb(0 0 0 / 80%)',
        flexDirection: 'column',
        margin: 'auto',
      }}
      onTransitionEnd={(event) => {
        if (
          event.currentTarget === event.target &&
          event.propertyName === 'opacity'
        ) {
          closeContactsForm();
        }
      }}
      data-testid="contacts-form"
    >
      <LogoutButton />
      <Box
        bgcolor="common.white"
        px="42px"
        borderRadius="10px"
        position="relative"
        boxShadow={shadows[DsShadow.SHADOW]}
        maxWidth={455}
      >
        <Form
          onSubmit={({
            firstName,
            lastName,
            company,
            countryCode,
            phone,
          }: Yup.InferType<typeof contactsFormSchema>) => {
            submitFormData({
              firstName,
              lastName,
              company,
              phone: `${countryCode?.phone ?? ''}${phone.replaceAll(' ', '')}`,
            });
          }}
          validate={validate}
          render={({ handleSubmit }) => (
            <>
              <Stack
                direction="row"
                justifyContent="center"
                top={-(logoSize / 2)}
                left={0}
                right={0}
                zIndex={1}
                position="absolute"
              >
                <HexIconSuccess
                  size={logoSize}
                  icon={
                    isSubmitted ? (
                      <Icons.CheckCircle
                        weight="fill"
                        color={palette.green[500]}
                      />
                    ) : (
                      <StepperIcon color="green.500" />
                    )
                  }
                />
              </Stack>

              <Collapse
                orientation="vertical"
                in={!isSubmitted}
                sx={{ minWidth: '371px' }}
                timeout={800}
              >
                <Stack gap="16px" mb="38px">
                  <Typography mt="43px" variant="h5" textAlign="center">
                    Complete your account setup
                  </Typography>
                  <RffInput
                    size="medium"
                    name="firstName"
                    id="firstName"
                    label="First name"
                    placeholder="John"
                  />
                  <RffInput
                    size="medium"
                    name="lastName"
                    id="lastName"
                    label="Last name"
                    placeholder="Smith"
                  />
                  <RffInput
                    size="medium"
                    name="company"
                    id="company"
                    label="Company name"
                    placeholder="e.g. Google"
                  />

                  <Box
                    display="grid"
                    gridTemplateColumns="1fr 2fr"
                    gridTemplateRows="18px auto"
                    columnGap="8px"
                    rowGap="4px"
                  >
                    <Typography
                      variant="P12M"
                      component="p"
                      color="grey.900"
                      mb="4px"
                      gridColumn="1 / -1"
                    >
                      Phone number
                    </Typography>

                    <CountryCodeAutocomplete name="countryCode" />

                    <RffInput
                      autoComplete="off"
                      size="medium"
                      name="phone"
                      placeholder="000 000 000"
                      fullWidth
                      required
                      fieldProps={{
                        parse: removeHiddenChars,
                      }}
                    />
                  </Box>

                  <Button
                    size="medium"
                    variant="secondary"
                    onClick={handleSubmit}
                    sx={{ mt: '16px' }}
                    fullWidth
                  >
                    Continue
                  </Button>
                </Stack>
              </Collapse>
            </>
          )}
        />
      </Box>
      <Stack
        mt="32px"
        gap="16px"
        alignItems="center"
        visibility={isSubmitted ? 'hidden' : 'visible'}
      >
        <Typography variant="h6" color="white">
          Leading companies automate their Kubernetes with CAST AI
        </Typography>
        <BrandsSvg />
      </Stack>
    </Box>
  );
};
