import { useState } from 'react';

import { HubspotContact } from '@cast/types';

import { IS_PROD_US } from 'common/constants';
import { useAuth } from 'core/auth';
import { useHubspotContact } from 'core/hubspot';
import { PermissionType, useRBAC } from 'core/rbac';
import {
  useCreateFullHubspotContactMutation,
  useCreateHubspotContactMutation,
} from 'hooks/mutations/marketing';
import { useUpdateOrganizationMutation } from 'hooks/mutations/organization';
import { useOrganizations } from 'hooks/useOrganizations';
import { usePersistentState } from 'hooks/usePersistentState';

export const useContactsForm = () => {
  const { user } = useAuth();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { currentOrganization } = useOrganizations();
  const { contactState } = useHubspotContact();
  const [showForm, setShowForm] = usePersistentState(
    `phone-form-${user?.id}`,
    IS_PROD_US && user?.firstLogin && contactState === 'pending'
  );
  const [canEdit] = useRBAC(PermissionType.EDIT, 'ORGANIZATION');

  const { success } = useCreateHubspotContactMutation({
    pageName: 'Signup form - initial',
  });

  const { mutate: submitContactsForm } = useCreateFullHubspotContactMutation({
    pageName: 'Signup form - full',
    enabled: success && IS_PROD_US,
  });

  const closeContactsForm = () => {
    setShowForm(false);
  };

  const { mutate: updateOrganization } = useUpdateOrganizationMutation();

  const submitHubspotData = (data: HubspotContact) => {
    if (IS_PROD_US) {
      submitContactsForm(data);
    }
    setIsSubmitted(true);
    closeContactsForm();
  };

  const submitFormData = (data: HubspotContact) => {
    if (currentOrganization?.id) {
      if (canEdit) {
        updateOrganization(
          { id: currentOrganization.id, name: data.company },
          {
            onSuccess: () => {
              submitHubspotData(data);
            },
          }
        );
      } else {
        submitHubspotData(data);
      }
    }
  };

  return {
    submitFormData,
    showForm: showForm && success && contactState === 'pending',
    closeContactsForm,
    isSubmitted,
  };
};
