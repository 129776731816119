import * as Yup from 'yup';

export const contactsFormSchema = Yup.object({
  firstName: Yup.string().required('Required field').label('First name'),
  lastName: Yup.string().required('Required field').label('Last name'),
  company: Yup.string()
    .required('Required field')
    .min(2, 'Input must be at least two characters long. ')
    .matches(
      /^[aA-zZ\s-._0-9]+$/,
      'Invalid company name - only alphabets, numbers and ".-_" are allowed'
    )
    .label('Company name'),
  countryCode: Yup.object()
    .nullable()
    .test('code selected', 'Required field', (value) => !!value)
    .shape({
      code: Yup.string(),
      label: Yup.string(),
      phone: Yup.string(),
    }),
  phone: Yup.lazy((value, context) => {
    const countryCode = context.parent.countryCode?.code;
    return Yup.string()
      .phone(countryCode, 'Invalid phone number')
      .required('Required field');
  }),
});
