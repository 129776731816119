import axios, { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { AXIOS_CLIENT_TIMEOUT } from '@cast/core';

import { AccessKeysApiFactory } from './access-keys-api-factory';
import { AuditLogApiFactory } from './audit-log-api-factory';
import { AuthApiFactory } from './auth-api-factory';
import { AutoscalerApiAdapter } from './autoscaler-api-adapter';
import { BillingApiFactory } from './billing-api-factory';
import { ClusterApiAdapter } from './cluster-api-adapter';
import { CommitmentsApiAdapter } from './commitments-api-adapter';
import { CostReportApiAdapter } from './cost-report-api-adapter';
import { FeatureFlagsApiFactory } from './feature-flags-factory';
import { HubspotApiFactory } from './hubspot-api-factory';
import { LLMOptimizationApiFactory } from './llm-optimization-api-factory';
import { NodeConfigTemplatesApiAdapter } from './node-config-templates-api-adapter';
import { NotificationsApiAdapter } from './notifications-api-adapter';
import { ReferralsApiFactory } from './referrals-api-factory';
import { ReportsApiAdapter } from './reports-api-adapter';
import { RuntimeSecurityApiAdapter } from './runtime-security-api-factory';
import { ScheduledRebalancingApiAdapter } from './scheduled-rebalancing-api-adapter';
import { SecurityInsightsApiAdapter } from './security-insights-api-adapter';
import { SSOApiAdapter } from './sso-api-factory';
import { UsersApiAdapter } from './users-api-adapter';
import { WorkloadOptimizationApiAdapter } from './workload-optimization-api-adapter';
import { YamlPreviewApiAdapter } from './yaml-preview-api-adapter';
import { AutoscalerApiProxy } from '../demo-api-client/autoscaler-api-proxy';
import { ClusterApiProxy } from '../demo-api-client/cluster-api-proxy';
import { CostReportApiProxy } from '../demo-api-client/cost-report-api-proxy';
import { NodeConfigTemplatesApiProxy } from '../demo-api-client/node-config-templates-api-proxy';
import { RuntimeSecurityApiProxy } from '../demo-api-client/runtime-security-api-proxy';
import { ScheduledRebalancingApiProxy } from '../demo-api-client/scheduled-rebalancing-api-proxy';
import { SecurityInsightsApiProxy } from '../demo-api-client/security-insights-api-proxy';
import { WorkloadOptimizationApiProxy } from '../demo-api-client/workload-optimization-api-proxy';

dayjs.extend(isSameOrAfter);

export const ApiClientFactory = (client: AxiosInstance) => {
  return {
    auth: AuthApiFactory(client),
    accessKeys: AccessKeysApiFactory(client),
    hubspot: HubspotApiFactory(client),
    featureFlags: FeatureFlagsApiFactory(client),
    auditLog: AuditLogApiFactory(client),
    billing: BillingApiFactory(client),
    costReport: CostReportApiProxy(CostReportApiAdapter(client)),
    clusters: ClusterApiProxy(ClusterApiAdapter(client)),
    autoscaler: AutoscalerApiProxy(AutoscalerApiAdapter(client)),
    scheduledRebalancing: ScheduledRebalancingApiProxy(
      ScheduledRebalancingApiAdapter(client)
    ),
    securityInsights: SecurityInsightsApiProxy(
      SecurityInsightsApiAdapter(client)
    ),
    runtimeSecurity: RuntimeSecurityApiProxy(RuntimeSecurityApiAdapter(client)),
    user: UsersApiAdapter(client),
    reports: ReportsApiAdapter(client),
    nodeConfigTemplates: NodeConfigTemplatesApiProxy(
      NodeConfigTemplatesApiAdapter(client)
    ),
    notifications: NotificationsApiAdapter(client),
    commitments: CommitmentsApiAdapter(client),
    workloadOptimization: WorkloadOptimizationApiProxy(
      WorkloadOptimizationApiAdapter(client)
    ),
    sso: SSOApiAdapter(client),
    yamlPreview: YamlPreviewApiAdapter(client),
    llmOptimization: LLMOptimizationApiFactory(client),
  };
};
// Need to create a separate client because referral API does not accept X-CastAi-Organization-Id header
const refClient = axios.create({
  timeout: AXIOS_CLIENT_TIMEOUT,
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

export const referralClient = ReferralsApiFactory(refClient);
