import { useCallback } from 'react';

import * as Sentry from '@sentry/react';

import { DataTable, LogPayload, RowKey } from '@cast/design-system';

import { analyticsEvents } from 'core/analytics';

import { NormalizedSearchableTableProps, SearchableTableProps } from './types';
import { useSearchContext } from '../hooks';
import { SearchState } from '../types';

const useProps = (
  { ...props }: SearchableTableProps,
  ctx: SearchState
): NormalizedSearchableTableProps => {
  return {
    ...props,
    hasFailed: props.hasFailed || !!ctx.error,
    urlKey: ctx.urlKey,
    isLoading: ctx.isLoading,
  };
};

export const SearchableTable = <T = any, K extends RowKey = any>(
  props: SearchableTableProps<T, K>
) => {
  const ctx = useSearchContext();
  if (!ctx) {
    throw new Error('Searchable table has to be used in SearchContext');
  }
  const normalizedProps = useProps(props, ctx);
  const { children, isLoading, components, hasFailed, ...rest } =
    normalizedProps;

  const noResultsFound =
    (ctx.data?.length && !ctx.filteredData?.length) ||
    ctx.noResultWithAppliedFilters;
  const showNoResultsFound = Boolean(
    !hasFailed && components?.noResults && noResultsFound
  );
  const showNoData =
    !hasFailed &&
    !showNoResultsFound &&
    !!components?.noData &&
    !ctx.data?.length &&
    !isLoading &&
    (!ctx.serverSideSort || ctx.sortingState !== null);

  const showFailed = hasFailed && !!components?.failed && !isLoading;

  const isMessageVisible =
    (showNoResultsFound && !!components?.noResults) ||
    (showNoData && !!components?.noData) ||
    (showFailed && !!components?.failed);

  const log = useCallback(({ message, data, logLevel }: LogPayload) => {
    Sentry.captureMessage(message, { level: logLevel, extra: data });
  }, []);

  return (
    <>
      {!isMessageVisible && (
        <DataTable
          onLoadMore={
            !ctx.isLoading && !ctx.isFetching && ctx.hasNextPage
              ? ctx.fetchNextPage
              : undefined
          }
          {...rest}
          onDataSort={(newState) => {
            const analyticsId =
              normalizedProps.analyticsId || normalizedProps.urlKey;
            if (analyticsId) {
              analyticsEvents.tableSortedEvent(analyticsId, newState);
            }
            normalizedProps.onDataSort?.(newState);
            if (ctx?.serverSideSort) {
              ctx?.setSortingState?.(newState);
            }
          }}
          onSortingChanged={!ctx.serverSideSort ? ctx.setSortFn : undefined}
          controlledSorting={ctx.serverSideSort}
          data={ctx.filteredData}
          isLoading={isLoading}
          isFetching={ctx.isFetching}
          log={log}
        >
          {children}
        </DataTable>
      )}
      {showFailed && components?.failed}
      {showNoData && components?.noData}
      {showNoResultsFound && components?.noResults}
    </>
  );
};
